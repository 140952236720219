// import { Button } from "react-bootstrap";
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';

const Footer = (props) => {
    return (
        <footer className="mt-auto">
            <Container>
                <p>
                    &copy; 2024 Mitsidi y BID | ¿Tienes alguna duda? Envía un correo a: <a href="mailto:bid@mitsidi.com">bid@mitsidi.com</a>
                </p>
            </Container>
        </footer>
    )
};

export default Footer;

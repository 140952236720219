import React, { useEffect } from 'react';
import { Button, Row, Col, Accordion } from 'react-bootstrap';
import NumericInput from './NumericInput';
import IntegerInput from './IntegerInput';
import Tooltip from 'bootstrap/js/dist/tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/card-equipamentos.css';

const ListaEquipamentos = ({ formData, handleChange, equipamentos, title, spanTitle }) => {
    title = title ? title : "Lista de Equipos Presentes";
    spanTitle = spanTitle ? spanTitle : "Complete los equipos presentes y sus respectivos valores";

    return (
        <>
            <h3>
                {title}
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={spanTitle}
                >
                    <i className="bi bi-info-circle-fill custom-tooltip"></i>
                </span>
            </h3>

            <Row className="align-items-center">
                {equipamentos.map((item, index) => {
                    return (
                        <Col xs={12} sm={6}>
                            <div className={`form-check-equipamento div_${item.id} custom-checkbox-wrapper`}>
                                {item.img && <img src={item.img} alt={item.label} className="custom-checkbox-image" />}
                                <input
                                    className="form-check-input custom-checkbox-input"
                                    type="checkbox"
                                    name={item.name}
                                    id={item.id}
                                    value={item.name}
                                    checked={formData[item.name]}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label custom-checkbox-label" htmlFor={item.id}>
                                    {item.label}
                                    {item.tooltip && (
                                        <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={item.tooltip}
                                        >
                                        <i className="bi bi-info-circle-fill custom-tooltip"></i>
                                        </span>
                                    )}
                                </label>
                            </div>
                        </Col>
                    );
                })}

            </Row>
        </>
    );
};


const Cards = ({ formData, handleChange, equipamentos }) => {
    return (
        <Accordion defaultActiveKey="0">
            {equipamentos.map((item, index) => {
                return (
                    formData[item.name] === true && (
                        <Accordion.Item eventKey={`${index}`}>
                            <Accordion.Header>{item.label}</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {item.potencia_btu === true && (
                                        <Col xs={12} sm={12}>
                                            <label htmlFor={`potencia_btu_${item.name}`}>Potencia (BTU/h):</label>
                                            <NumericInput
                                                id={`potencia_btu_${item.id}`}
                                                name={`potencia_btu_${item.name}`}
                                                className="form-control otros-equipos-info"
                                                value={formData[`potencia_btu_${item.name}`]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    )}
                                    <Col xs={12} sm={12}>
                                        <label htmlFor={`potencia_${item.id}`}>Potencia Eléctrica (W):</label>
                                        <NumericInput
                                            id={`potencia_${item.id}`}
                                            name={`potencia_${item.name}`}
                                            className="form-control iluminacao-interna-info"
                                            value={formData[`potencia_${item.name}`]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    {item.capacidad_de_enfriamiento === true && (
                                        <Col xs={12} sm={12}>
                                            <label htmlFor={`capacidad_de_enfriamiento_${item.name}`}>Capacidad de enfriamiento (W):</label>
                                            <NumericInput
                                                id={`capacidad_de_enfriamiento_${item.id}`}
                                                name={`capacidad_de_enfriamiento_${item.name}`}
                                                className="form-control otros-equipos-info"
                                                value={formData[`capacidad_de_enfriamiento_${item.name}`]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    )}
                                    {item.reee === true && (
                                        <Col xs={12} sm={12}>
                                            <label htmlFor={`reee_${item.name}`}>Relación de Eficiencia Energética Estacional (REEE):</label>
                                            <NumericInput
                                                id={`reee_${item.id}`}
                                                name={`reee_${item.name}`}
                                                className="form-control otros-equipos-info"
                                                value={formData[`reee_${item.name}`]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    )}
                                    <Col xs={12} sm={12}>
                                        <label htmlFor={`cantidad_${item.id}`}>Cantidad:</label>
                                        <IntegerInput
                                            id={`cantidad_${item.id}`}
                                            name={`cantidad_${item.name}`}
                                            className="form-control iluminacao-interna-info"
                                            value={formData[`cantidad_${item.name}`]}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    {item.capacidad_litros === true && (
                                        <Col xs={12} sm={12}>
                                            <label htmlFor={`capacidad_litros_${item.name}`}>Capacidad (litros):</label>
                                            <NumericInput
                                                id={`capacidad_litros_${item.id}`}
                                                name={`capacidad_litros_${item.name}`}
                                                className="form-control otros-equipos-info"
                                                value={formData[`capacidad_litros_${item.name}`]}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    )}
                                    {item.inverter &&(
                                        <Col xs={12} sm={12}>
                                            <label htmlFor="estado">
                                                ¿Dispone de tecnología inverter?:
                                            </label>
                                            <span
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Aire acondicionado Split inverter: La unidad exterior tiene un compresor que funciona a velocidad variable para mantener la temperatura deseada. Esto los hace más eficientes energéticamente y más silenciosos que los aires acondicionados Split tradicionales. La unidad interior también tiene un ventilador que hace circular el aire sobre los serpentines de enfriamiento."
                                            >
                                                <i className="bi bi-info-circle-fill custom-tooltip"></i>
                                            </span>
                                            <select
                                                id={`inverter_${item.id}`}
                                                name={`inverter_${item.name}`}
                                                className="form-control ar-condicionado-info"
                                                value={formData[`inverter_${item.name}`]}
                                                onChange={handleChange}
                                            >
                                                <option value='' hidden></option>
                                                <option value="Sí">Sí</option>
                                                <option value="No">No</option>
                                            </select>
                                        </Col>
                                    )}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                );
            })}
        </Accordion>
    );
};

const CardsEquipamentos = ({ formData, setFormData, handleChange, equipamentos, props={} }) => {
    return (
        <>
            <ListaEquipamentos
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                equipamentos={equipamentos}
                title={props.title}
                spanTitle={props.spanTitle}
            />

            <Cards 
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                equipamentos={equipamentos}
            />
        </>
    )
};

export default CardsEquipamentos;

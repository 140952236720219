import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../css/card-equipamentos.css';

const MultiselectSimple = ( {formData, handleChange, label, itens} ) => {

  return (
    <>
      <label>
        {label}
      </label>

      <Row className="align-items-center">
        {itens.map((item, index) => {
            return (
                <Col xs={12} sm={12}>
                    <div className={`form-check-equipamento div_${item.id} custom-checkbox-input-inline`}>
                        <input
                            className="form-check-input custom-checkbox-input-inline"
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                            value={item.name}
                            checked={formData[item.name]}
                            onChange={handleChange}
                        />
                        <label className="form-check-label custom-checkbox-label" htmlFor={item.id}>
                            {item.label}
                        </label>
                    </div>
                </Col>
            );
        })}

    </Row>
    </>
  );
};

export default MultiselectSimple;
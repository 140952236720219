// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btn-circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    line-height: 16px; /* Match the height to center the icon */
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/css/popover.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,iBAAiB,EAAE,wCAAwC;IAC3D,kBAAkB;AACtB","sourcesContent":["\n.btn-circle {\n    border-radius: 50%;\n    width: 20px;\n    height: 20px;\n    padding: 0;\n    line-height: 16px; /* Match the height to center the icon */\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

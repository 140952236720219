// Home.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import PopoverComponent from './components/PopoverComponent';
import axios from 'axios';
import './css/home.css';

const Home = ( props ) => {

  const [formData, setFormData] = useState({ codigo: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    navigate(`/form/${formData.codigo}`);
  
    // const postUrl = `${props.BACKEND_URL}/api`
    // console.log(postUrl)

    // const data = new FormData();

    // data.append('codigo', formData['codigo']);

    // axios.post(postUrl, data)
    // .then(response => {
    //     console.log('Form submitted successfully:', response);
    //     navigate(`/form/${formData.codigo}`);
    // })
    // .catch(error => {
    //     console.error('Error submitting form:', error);
    //     alert("Ocorreu um erro no envio de código")
    // });
  };

  return (
    <div id="home">
      <header className="mb-4">
          <Container className="d-flex justify-content-between align-items-center py-3">
              <img src="/img/BID_espanhol.png" alt="Logo BID" className="logo-mitsidi logo-header"/>
              <img src="/img/logo_mitsidi.png" alt="Logo Mitsidi" className="logo-mitsidi logo-header" />
          </Container>
      </header>
      <Container className="d-flex justify-content-center align-items-center">
        <div className="form-container">
          <h1 className="text-center mb-4">Bienvenido</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="codigo" className="form_formulario">
              <Form.Label>
                Ingrese su Clave de Centro de Trabajo (CCT) para acceder al formulario
                <PopoverComponent text="La contraseña de acceso del Estado es un código compuesto por una secuencia de 6 dígitos, proporcionado por los gestores de las escuelas."/>
              </Form.Label>
              <Form.Control
                type="text"
                required
                value={formData.codigo}
                onChange={handleChange}
              />
            </Form.Group>
              <Row>
                <Col xs={12} sm={12}>
                  <Button className="button_formulario" variant="primary" type="submit" block>
                    Acceder al Formulario
                  </Button>
                </Col>
                <Col xs={12} sm={12} style={{ marginTop: '0.5em'}}>
                  El llenado de la plataforma seguirá los siguientes pasos:
                </Col>
                <Col xs={12} sm={12} style={{ marginTop: '0.5em'}}>
                  En la primera etapa, será necesario ingresar los datos generales de la escuela, como nombre, ubicación y más...
                </Col>
                <Col xs={12} sm={12} style={{ marginTop: '0.5em'}}>
                  En la segunda etapa, deberás completar los datos técnicos, incluyendo información sobre la estructura, el techo, la existencia de energía renovable y los horarios de uso de la energía. Si no cuentas con datos exactos, podrás hacer estimaciones.
                </Col>
                <Col xs={12} sm={12} style={{ marginTop: '0.5em'}}>
                  En la tercera etapa, deberás proporcionar el inventario de equipos, como iluminación interna y externa, así como los sistemas de aire acondicionado.
                  {/* Si tienes alguna duda o necesitas una explicación más detallada sobre cómo utilizar la herramienta, descarga nuestro tutorial en PDF. */}
                </Col>
                <Col xs={12} sm={12} className='d-flex justify-content-center'>
                <a
                  className="button_formulario btn btn-secondary"
                  href={`${props.BACKEND_URL}/download-pdf`}
                  download="tutorial.pdf"
                  style={{ width: '80%', display: 'inline-block', textAlign: 'center' }}
                >
                  Descargar Encuesta en PDF
                </a>
                </Col>
              </Row>
              

              {/* <Button className="button_formulario" variant="primary" type="submit" block>
                Baixar Tutorial
              </Button> */}
          </Form>
        </div>
      </Container>
      <footer className="mt-auto">
        <Container>
          <p>&copy; 2024 Mitsidi y BID</p>
        </Container>
      </footer>
    </div>
  );
};

export default Home;

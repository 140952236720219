import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import NumericInput from '../../NumericInput'; // Adjust the path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const CollapsibleCardButton = ({ isCollapsed, onClick }) => {
  return (
    <Button
      type="button"
      className="btn btn-info"
      onClick={onClick}
      aria-expanded={!isCollapsed}
    >
      <i className={`fas ${isCollapsed ? 'fa-plus' : 'fa-minus'}`} style={{ color: 'white'}}></i>
    </Button>
  );
};

const FieldsCard = ({ index, formData, handleChange }) => {
  const mesNome = `mes_${index}`;

  return (
    <div>
      <label htmlFor={`consumo_${mesNome}`}>
        Consumo total (kWh)*:
      </label>
      <NumericInput
        id={`consumo_${mesNome}`}
        name={`consumo_${mesNome}`}
        className="form-control energia-info month-info"
        value={formData[`consumo_${mesNome}`]}
        onChange={handleChange}
        required
      />

      <label htmlFor={`tarifa_${mesNome}`}>
        Tarifa ($MXN/kWh)*:
      </label>
      <NumericInput
        id={`tarifa_${mesNome}`}
        name={`tarifa_${mesNome}`}
        className="form-control energia-info month-info"
        value={formData[`tarifa_${mesNome}`]}
        onChange={handleChange}
        required
      />

      <label htmlFor={`costo_${mesNome}`}>
        Costo total pagado ($MXN):
      </label>
      <NumericInput
        id={`costo_${mesNome}`}
        name={`costo_${mesNome}`}
        className="form-control energia-info month-info"
        value={formData[`costo_${mesNome}`]}
        onChange={handleChange}
      />

      <label htmlFor={`energia_reactiva_${mesNome}`}>
        Energía reactiva (kVArh):
      </label>
      <NumericInput
        id={`energia_reactiva_${mesNome}`}
        name={`energia_reactiva_${mesNome}`}
        className="form-control month-info"
        value={formData[`energia_reactiva_${mesNome}`]}
        onChange={handleChange}
      />

      <label htmlFor={`factor_potencia_${mesNome}`}>
        Factor de potencia (%):
      </label>
      <NumericInput
        id={`factor_potencia_${mesNome}`}
        name={`factor_potencia_${mesNome}`}
        className="form-control month-info"
        value={formData[`factor_potencia_${mesNome}`]}
        onChange={handleChange}
      />
    </div>
  );
};

const MonthCard = ({ mes, index, formData, handleChange }) => {
  const [isCollapsed, setIsCollapsed] = useState(index==0 ? false: true);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!mes[0]) {
    return null;
  }

  return (
    <Card key={index} className="mt-4 w-100" id={`card_month_${index}`} name={`${mes[0]} - ${mes[1]}`}>
      <Card.Header>
        <div className="d-flex justify-content-between align-items-center">
          <span>{mes[0]} - {mes[1]}</span>
          <CollapsibleCardButton isCollapsed={isCollapsed} onClick={handleCollapse} />
        </div>
      </Card.Header>
      <Card.Body id={`collapse-${index}`} className={isCollapsed ? 'collapse' : 'show'}>
        <FieldsCard index={index} formData={formData} handleChange={handleChange} />
      </Card.Body>
    </Card>
  );
};

const Months = ({ monthsPage, formData, handleChange }) => {
  return (
    <>
      {monthsPage.map((mes, index) => (
        <MonthCard mes={mes} index={index} formData={formData} handleChange={handleChange} key={index} />
      ))}
    </>
  );
};

export default Months;

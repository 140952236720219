// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .logo-container img {
    height: 50px;
  }
  .container {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-container {
    max-width: 500px;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  footer {
    text-align: center;
    padding: 10px;
    background: #f8f9fa;
  }

  .button_formulario {
    width: 100%;
    height: 40px;
    margin-top: 12px;
  }

`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;EACA;IACE,YAAY;EACd;EACA;IACE,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uCAAuC;IACvC,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":["  body {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n  }\n  .logo-container img {\n    height: 50px;\n  }\n  .container {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .form-container {\n    max-width: 500px;\n    width: 100%;\n    background: #fff;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    margin: auto;\n  }\n  footer {\n    text-align: center;\n    padding: 10px;\n    background: #f8f9fa;\n  }\n\n  .button_formulario {\n    width: 100%;\n    height: 40px;\n    margin-top: 12px;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// App.js
import React, {  useState  } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.css';

import Home from './Home';
import Formulario from './Form';

const App = () => {
  // Adicionar keys futuras para acessar o backend
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:5000';

  // console.log(UNSPLASH_KEY);

  // console.log(process.env)
  // console.log(school)

  return (
    <Router>
      <div id="app">
        {/* <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/form/slug-este">Form</Link></li>
          </ul>
        </nav> */}
        <Routes>
          <Route path="/" element={<Home BACKEND_URL={BACKEND_URL}/>} />
          <Route path="/form/:slug" element={<Formulario BACKEND_URL={BACKEND_URL}/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';

const EnergiaRenovableInfo = ({ formData, handleChange, handleFileChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  return (
    <section className="form-section active" id="section-energia-renovable">
      <h2>
        Energía Renovable
      </h2>

      <label>
        ¿Ya tiene alguna acción de energía renovable? (Fotovoltaica/Paneles solares)*
        <span
          data-toggle="tooltip"
          data-placement="top"
          title='
          Para determinar la potencia instalada del sistema fotovoltaico, simplemente multiplica la potencia de cada panel fotovoltaico por la cantidad total de 
          paneles instalados. Por ejemplo, si cada panel tiene una potencia de 500 W y hay 12 paneles instalados, la potencia instalada sería 500 W × 12 paneles = 6000 W, o 6 kW, donde "k" representa mil.'
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
      </label>

      <img src='/img/form/energia_renovavel/paneles_solares.jpg' alt='Exemplo Painéis Solares' className="w-100" />

      <label className='img-label'>Ejemplo de paneles solares</label>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="energia_renovable"
          id="energia_renovable_si"
          value="sí"
          checked={formData.energia_renovable === 'sí'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="energia_renovable_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="energia_renovable"
          id="energia_renovable_no"
          value="no"
          checked={formData.energia_renovable === 'no'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="energia_renovable_no">No</label>
      </div>

      {formData.energia_renovable === 'sí' && (
        <>
          <label htmlFor="potencia_sistema">
            Si es así, ¿cuál es la potencia del sistema (kWp)?
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="
              Para determinar la potencia instalada del sistema fotovoltaico, simplemente multiplica la potencia de cada panel fotovoltaico por la cantidad total de 
              paneles instalados. Por ejemplo, si cada panel tiene una potencia de 500 W y hay 12 paneles instalados, la potencia instalada sería 500 W × 12 paneles = 6000 W, o 6 kW, donde 'k' representa mil."
            >
              <i className="bi bi-info-circle-fill custom-tooltip"></i> 
            </span>
          </label>
          <NumericInput
            id="potencia_sistema"
            name="potencia_sistema"
            className="form-control"
            value={formData.potencia_sistema}
            onChange={handleChange}
          />

          <label htmlFor="cantidad_placas">
            Si no sabe la potencia, ¿cuál es la cantidad de paneles?
          </label>
          <IntegerInput
            id="cantidad_placas"
            name="cantidad_placas"
            className="form-control"
            value={formData.cantidad_placas}
            onChange={handleChange}
          />
        </>
      )}

      <div>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default EnergiaRenovableInfo;

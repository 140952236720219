import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';
import CardsEquipamentos from '../CardsEquipamentos';

const Outros = ({ formData, handleChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  const outrosEquipos = [
    {
      label: 'Ordenador',
      id: "ordenador",
      name: "ordenador",
      capacidad: false,
      img: '/img/form/outros/ordenador.png',
    },
    {
      label: 'Notebook',
      id: "notebook",
      name: "notebook",
      capacidad: false,
      img: '/img/form/outros/notebook.png',
    },
    {
      label: 'Horno Eléctrico',
      id: "horno_eletrico",
      name: "horno_eletrico",
      capacidad_litros: true,
      img: '/img/form/outros/forno.png',
    },
    // { tipo: 'Congelador', name_frontend: "congelador", img: '/img/form/outros/congelador.png' },
    // { tipo: 'Refrigerador', name_frontend: "refrigerador", img: '/img/form/outros/refrigerador.png' },
  ]

  return (
    <section className="form-section active" id="section-outros-equipos">
      <h2>Otros equipos</h2>
      
      <CardsEquipamentos formData={formData} handleChange={handleChange} equipamentos={outrosEquipos} />

      <div>
        <label>Otros:</label>
        <span
          data-toggle="tooltip"
          data-placement="top"
          title='Por favor, especifique.'
        >
          <i className="bi bi-info-circle-fill custom-tooltip"></i>
        </span>
        <textarea
          style={{ height: '8em' }}
          type="text"
          name="otros_especifique"
          className="form-control otros-equipos-info"
          value={formData.otros_especifique}
          onChange={(e) => handleChange({ target: { name: 'otros_especifique', value: e.target.value } })}
        >{formData.otros_especifique}</textarea>
      </div>

      <div style={{ marginTop: '1.5em' }}>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        {/* <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button> */}
        <Button className="btn btn-info mt-2 text-white" variant="primary" type="submit" block>
          <strong>Enviar</strong>
        </Button>
      </div>
    </section>
  );
};

export default Outros;

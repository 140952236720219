import React, { useEffect } from 'react';
import { Button, Row, Col, Accordion, Card } from 'react-bootstrap';
import NumericInput from '../../NumericInput';
import IntegerInput from '../../IntegerInput';
import Tooltip from 'bootstrap/js/dist/tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../../../css/card-equipamentos.css';

const CamposAdicionais = ({ formData, handleChange, name, id, label }) => {
    const numbersCcts = Array.from({ length: parseInt(formData["numero_ccts_complementares"]) }, (_, index) => index);

    return (
        <>
            {numbersCcts.map((_, index) => (
                <>
                    <label htmlFor={`${id}_${index}`}>{`${label}: ${index + 1}`}</label>
                    <input
                    type="text"
                    id={`${id}_${index}`}
                    name={`${name}_${index}`}
                    className="form-control general-info"
                    value={formData[`${name}_${index}`]}
                    onChange={handleChange}
                    />
                </>
            ))}
        </>
    );
};

const CardCctsComplmentares = ({ formData, setFormData, handleChange }) => {
    const minEquipos = 1;
    const maxEquipos = 5;

    useEffect(() => {
        // If the current value is negative or zero, replace it with 1
        if (formData["numero_ccts_complementares"] < minEquipos || !formData["numero_ccts_complementares"]) {
            setFormData({
                ...formData,
                ["numero_ccts_complementares"]: minEquipos
            });
        } else if (formData["numero_ccts_complementares"] > maxEquipos) {
            setFormData(
                {
                    ...formData,
                    ["numero_ccts_complementares"]: maxEquipos
            });
        }
    }, [formData]);

    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey={`0`}>
                <Accordion.Header>
                    CCTs Complementarios
                </Accordion.Header>
                <Accordion.Body>
                    <label htmlFor="numero_ccts_complementares">
                        Número de CCTs Complementarios:
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Introduzca el número de CCTs complementarias presentes, entre ${minEquipos} y ${maxEquipos}`}
                        >
                            <i className="bi bi-info-circle-fill custom-tooltip"></i>
                        </span>
                    </label>
                    <input
                        id="numero_ccts_complementares"
                        name="numero_ccts_complementares"
                        type="number"
                        value={formData["numero_ccts_complementares"]? formData["numero_ccts_complementares"]: 1}
                        className={'mb-2'}
                        placeholder="Ex: 1,000"
                        min={minEquipos}
                        max={maxEquipos}
                        step={1}
                        onChange={handleChange}
                        onWheel={(event) => event.preventDefault()}
                        onFocus={(event) => event.target.addEventListener('wheel', (e) => e.preventDefault())}
                        onBlur={(event) => event.target.removeEventListener('wheel', (e) => e.preventDefault())}
                        onKeyDown={(event) => event.preventDefault()} 
                    />

                    <CamposAdicionais
                    formData={formData}
                    handleChange={handleChange}
                    name={'cct_complementar'}
                    id={'cct_complementar'}
                    label={"CCT Complementar"}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


        // <Card style={{ width: '100%', marginTop: '1rem' }}>
        //     <Card.Header>CCTs complementarios</Card.Header>
        //     <Card.Body>
        //         <label htmlFor="numero_ccts_complementares">
        //             Número de CCTs complementarios:
        //             <span
        //                 data-toggle="tooltip"
        //                 data-placement="top"
        //                 title={`Introduzca el número de CCTs complementarias presentes, entre ${minEquipos} y ${maxEquipos}`}
        //             >
        //                 <i className="bi bi-info-circle-fill custom-tooltip"></i>
        //             </span>
        //         </label>
        //         <input
        //             id="numero_ccts_complementares"
        //             name="numero_ccts_complementares"
        //             type="number"
        //             value={formData["numero_ccts_complementares"]? formData["numero_ccts_complementares"]: 1}
        //             className={'mb-2'}
        //             placeholder="Ex: 1,000"
        //             min={minEquipos}
        //             max={maxEquipos}
        //             step={1}
        //             onChange={handleChange}
        //             onWheel={(event) => event.preventDefault()}
        //             onFocus={(event) => event.target.addEventListener('wheel', (e) => e.preventDefault())}
        //             onBlur={(event) => event.target.removeEventListener('wheel', (e) => e.preventDefault())}
        //             onKeyDown={(event) => event.preventDefault()} 
        //         />
        //     </Card.Body>
        // </Card>
    )
}

const CctsComplementares = ({ formData, setFormData, handleChange }) => {
    return (
        <>
            <label>¿La escuela cuenta con algún cct complementario?</label>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cct_complementario"
                id="cct_complementario_si"
                value="sí"
                checked={formData.cct_complementario === 'sí'}
                onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="cct_complementario">Sí</label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                name="cct_complementario"
                id="cct_complementario_no"
                value="no"
                checked={formData.cct_complementario === 'no'}
                onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="cct_complementario_no">No</label>
            </div>

            {formData.cct_complementario === 'sí' && (
                <CardCctsComplmentares formData={formData} setFormData={setFormData} handleChange={handleChange}/>
            )}
        </>
    );
};

export default CctsComplementares;

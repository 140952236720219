import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';

const HorariosDeOperacion = ({ formData, handleChange, name, className }) => {
  return (
    <>
    <label>Horario de operación:</label>
      <div>
        <label>Lunes a viernes (Número de horas semanales):</label>
        <div className="form-group">
          <label htmlFor={`turno_manana_lunes_viernes${name}`}>Turno Mañana:</label>
          <NumericInput
            id={`turno_manana_lunes_viernes${name}`}
            name={`turno_manana_lunes_viernes${name}`}
            className={className}
            value={formData[`turno_manana_lunes_viernes${name}`]}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`turno_tarde_lunes_viernes${name}`}>Turno Tarde:</label>
          <NumericInput
            id={`turno_tarde_lunes_viernes${name}`}
            name={`turno_tarde_lunes_viernes${name}`}
            className={className}
            value={formData[`turno_tarde_lunes_viernes${name}`]}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`turno_nocturno_lunes_viernes${name}`}>Turno Nocturno:</label>
          <NumericInput
            id={`turno_nocturno_lunes_viernes${name}`}
            name={`turno_nocturno_lunes_viernes${name}`}
            className={className}
            value={formData[`turno_nocturno_lunes_viernes${name}`]}
            onChange={handleChange}
          />
        </div>
      </div>

      <div>
        <label>Fines de semana:</label>
        <div className="form-group">
          <label htmlFor={`turno_manana_fines_semana${name}`}>Turno Mañana:</label>
          <NumericInput
            id={`turno_manana_fines_semana${name}`}
            name={`turno_manana_fines_semana${name}`}
            className={className}
            value={formData[`turno_manana_fines_semana${name}`]}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`turno_tarde_fines_semana${name}`}>Turno Tarde:</label>
          <NumericInput
            id={`turno_tarde_fines_semana${name}`}
            name={`turno_tarde_fines_semana${name}`}
            className={className}
            value={formData[`turno_tarde_fines_semana${name}`]}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor={`turno_nocturno_fines_semana${name}`}>Turno Nocturno:</label>
          <NumericInput
            id={`turno_nocturno_fines_semana${name}`}
            name={`turno_nocturno_fines_semana${name}`}
            className={className}
            value={formData[`turno_nocturno_fines_semana${name}`]}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

const OcupacionInfo = ({ formData, handleChange, handleFileChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

const niveisEscolares = [
  // {label: "Inicial General", id: "inicial_general", name: "inicial_general"},
  {label: "Preescolar", id: "preescolar", name: "preescolar"},
  {label: "Primaria", id: "primaria", name: "primaria"},
  {label: "Secundaria", id: "secundaria", name: "secundaria"},
]

  return (
    <section className="form-section active" id="section-ocupacion">
      <h2>Ocupación y operación</h2>
      
      <label htmlFor="alumnos">
        Número de alumnos:
      </label>
      <IntegerInput
        id="alumnos"
        name="alumnos"
        className="form-control ocupacion_operacion-info"
        value={formData.alumnos}
        onChange={handleChange}
      />

      <label htmlFor="empleados">
        Número total de empleados (incluyendo los profesores, técnicos y administrativos):
      </label>
      <IntegerInput
        id="empleados"
        name="empleados"
        className="form-control ocupacion_operacion-info"
        value={formData.empleados}
        onChange={handleChange}
      />

      <label htmlFor="aulas">
        Número de aulas:
      </label>
      <NumericInput
        id="aulas"
        name="aulas"
        className="form-control ocupacion_operacion-info"
        value={formData.aulas}
        onChange={handleChange}
      />

      <label>
        Nivel(es) educativo(s) suministrado(s)*:
      </label>

      {niveisEscolares.map((item, index) => {
        return (
          <>
            <div className="form-check">
              <input
                className="form-check-input niveis_educativos"
                type="checkbox"
                name={item.name}
                id={item.id}
                value="niveis_educativos"
                checked={formData[item.name]}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor={item.id}>{item.label}</label>
            </div>
          </>
        )
      })}

      {/* antigo que aceitava apenas um verdadeiro */}
      {/* <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="niveles_educativos"
          id="nivel_preescolar"
          value="preescolar"
          checked={formData.niveles_educativos && formData.niveles_educativos.includes('preescolar')}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="nivel_preescolar">Preescolar</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="niveles_educativos"
          id="nivel_primaria"
          value="primaria"
          checked={formData.niveles_educativos && formData.niveles_educativos.includes('primaria')}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="nivel_primaria">Primaria</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="niveles_educativos"
          id="nivel_secundaria"
          value="secundaria"
          checked={formData.niveles_educativos && formData.niveles_educativos.includes('secundaria')}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="nivel_secundaria">Secundaria</label>
      </div> */}

      <HorariosDeOperacion formData={formData} handleChange={handleChange} name={''} className={'form-control horarios_operacion'}/>

      <label>¿Hay restaurante, cafetería o servicios de comida?</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="restaurante"
          id="restaurante_si"
          value="sí"
          checked={formData.restaurante === 'sí'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="restaurante_si">Sí</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="restaurante"
          id="restaurante_no"
          value="no"
          checked={formData.restaurante === 'no'}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="restaurante_no">No</label>
      </div>
      {/* {formData.restaurante === 'sí' && (
        <>
          <HorariosDeOperacion formData={formData} handleChange={handleChange} name={'_restaurante'} className={'form-control horarios_operacion_restaurante'}/>
        </>
      )} */}

      <div>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default OcupacionInfo;


import React, { useState, useEffect } from 'react';

const FileInput = ({ formData, handleFileChange, name, id, label, buttonLabel }) => {
    const [filename, setFilename] = useState(formData[name] ? formData[name]: '');

    useEffect(() => {
        // Ensure that formData[name] is an array before setting state
        if (formData[name] && typeof formData[name] === 'string') {
            setFilename(formData[name]);
        }
    }, [formData, name]);

    const handleFileChangeFinal = (event) => {
        const file = event.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes
        
        if (!file) {
            setFilename('');
            return;
        };
        
        if (file.size > maxSize) {
            alert("El tamaño del archivo excede el límite de 2MB.");
            return;
        };

        if (file.name.length > 512) {
            alert("El nombre tamaño del archivo supera el límite máximo de 512 caracteres.")
            return;
        };

        handleFileChange(event);

        setFilename(file.name);
    };

    return (
        <div style={{ marginBottom: '1.6em' }}>
            <label htmlFor={id}>{label}:</label>
            <br /> {/* Line break added here */}
            <input
                type="file"
                id={id}
                name={name}
                style={{ display: 'none' }}
                onChange={handleFileChangeFinal}
            />
            <label htmlFor={id} className="custom-file-label w-100">
                {filename ? `Arquivo Selecionado: ${filename}` : 'Selecione um arquivo'}
            </label>
            {buttonLabel && (
                <button
                type="button"
                onClick={() => document.getElementById(label).click()}
                className="btn btn-primary"
                >
                    {filename ? `Change ${buttonLabel}` : `Choose ${buttonLabel}`}
                </button>
            )}
            
        </div>
    );
};

export default FileInput;
// About.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import './css/form.css';

import GeneralInfo from './components/Form/GeneralInfo.js';
import TechoInfo from './components/Form/TechoInfo.js';
import OcupacionInfo from './components/Form/OcupationInfo.js';
import EnergiaInfo from './components/Form/Energia.js';
import EnergiaRenovableInfo from './components/Form/EnergiaRenovable.js';
import IluminationInternaInfo from './components/Form/IluminationInterna.js';
import IluminationExternaInfo from './components/Form/IluminationExterna.js';
import ArCondicionados from './components/Form/ArCondicionado.js';
import Outros from './components/Form/Outros.js';
import CaracteristicasArquitetonicas from './components/Form/CaracteristicasArquitectonicas.js';
import CaliamentoAgua from './components/Form/CaliamentoAgua.js';
import Footer from './components/Footer.js';

const Formulario = ( props ) => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const [currentSection, setCurrentSection] = useState('general_info');
    const listCurrentSections = [
        "general_info",
        "energia",
        "caracteristicas_arquitetonicas",
        "energia_renovable",
        "techo",
        "ocupacion_operacion",
        "iluminacion_interna",
        "iluminacion_externa",
        "ar_condicionados",
        // "caliamento_agua",
        // "outros",
    ];

    const [formData, setFormData] = useState({
        cct: '',
        nombre_encuestado: '',
        seguridad: '',
        fotos: [],
        niveles_educativos: []
    });

    useEffect(() => {
        console.log("axios ok!");
        // Fetch data from backend based on slug
        axios.get(`${props.BACKEND_URL}/api/form/${slug}/${currentSection}`)
        .then(response => {
            console.log("axios ok!");

            if (currentSection === 'techo') {
                if (response.data.tipo_area_techo === 'second') {
                    document.getElementById('techo_secound_nav_tab').click();
                };
            };

            setFormData(response.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [slug, currentSection]);

    const handleChange = (e) => {
        var { name, value } = e.target;

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        };

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const name = e.target.name;

        setFormData({
            ...formData,
            [name]: e.target.files
        });
    };

    const handleMultipleFileChange = (e) => {
        const name = e.target.name;
    
        setFormData({
            ...formData,
            [name]: [...e.target.files] // Spread the FileList into an array
        });
    };

    const validateRequiredFields = (querySelector='') => {
        if (!querySelector) {
            return true;
        }

        const requiredFields = document.querySelectorAll(querySelector);
        for (const field of requiredFields) {
            if (!field.value.trim()) {
                return false;
            }
        }
        return true;
    };

    const validateRequiredFieldsEnergia = (querySelectorEnergia='.energia-info[required]') => {
        if (!document.getElementById("acceso_energia_si").checked && !document.getElementById("acceso_energia_no").checked) {
            alert("Campo [Acceso Energia] no rellenado(s).")
            return false;
        };

        if (document.getElementById("acceso_energia_si").checked) {
            const requiredMonthInfo = Array.from(document.querySelectorAll('.month-info[required]'));
            for (let i=0; i<requiredMonthInfo.length; i++) {
                let element = requiredMonthInfo[i];

                if (!element.value.trim()) {
                    let [typeName, _, columnIndex] = element.name.split('_');
                    const cardElement = document.getElementById(`card_month_${columnIndex}`);
                    const cardName = cardElement.getAttribute('name');

                    alert(`Es necesario llenar el valor de ${typeName} de la columna de meses ${cardName}`)

                    cardElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest'
                    });

                    return false;
                };
            };

            return validateRequiredFields(querySelectorEnergia);
        };

        return true;
    };

    const validateRequiredFieldsEnergiaRenovable = (querySelector='.energia-renovable-info[required]') => {
        if (!document.getElementById("energia_renovable_si").checked && !document.getElementById("energia_renovable_no").checked) {
            alert("Campo [Accion de energía renovable] no rellenado(s).")
            return false;
        };

        if (document.getElementById("energia_renovable_si").checked) {
            if (!document.getElementById("potencia_sistema").value.trim() && !document.getElementById("cantidad_placas").value.trim()) {
                alert("Al menos el campo [Potencia del sistema (kWp)] o el campo [Cantidad de paneles] debe estar completado.")
                return false;
            }
            return validateRequiredFields(querySelector);
        };

        return true;
    };

    const validateRequiredFieldsArCondicionado = (querySelectorEnergia='.ar_condicionado-info[required]') => {
        // if (document.getElementById("calefaccion_si").checked) {
        //     if (!document.getElementById("potencia_eletrica_ar_condicionado").value.trim()) {
        //         alert("¡Es necesario completar el valor 'Potencia eléctrica (W)*' cuando hay un sistema eléctrico de calentamiento de agua!")
        //         return false;
        //     };
        //     if (!document.getElementById("cantidad_calefaccion_ar_condicionado").value.trim()) {
        //         alert("¡Es necesario completar el valor 'Cantidad*' cuando hay un sistema eléctrico de calentamiento de agua!")
        //         return false;
        //     };
        // };

        return validateRequiredFields(querySelectorEnergia);
    };

    const validateRequiredFieldsTecho = (querySelector='.techo-info[required]') => {
        // if (Array.from(document.getElementById('techo_first_nav_tab').classList).includes('active')) {
        //     if (!document.getElementById('area_total_techos').value.trim()) {
        //         alert("Área total del techo no rellenado!");
        //         return false;
        //     }
        // }  else if (Array.from(document.getElementById('techo_secound_nav_tab').classList).includes('active')) {
        //     if (!Array.from(document.querySelectorAll(".techo_coordinates")).some(element => element.value.trim())) {
        //         alert("Al menos una coordenada debe estar completa para continuar.")
        //         return false;
        //     };
        // }

        return validateRequiredFields(querySelector);
    };

    const validateRequiredFieldsOcupacionOperacion = (querySelectorEnergia='.ocupacion_operacion-info[required]') => {
        if (!Array.from(document.querySelectorAll(".niveis_educativos")).some(element => element.checked === true)) {
            alert("Al menos un nivel educativo debe estar completado para continuar.")
            return false;
        };

        // if (!Array.from(document.querySelectorAll(".horarios_operacion")).some(element => Boolean(element.value.trim()))) {
        //     alert("Al menos un horario de operación debe estar completado para continuar.");
        //     return false;
        // };

        // if (document.getElementById("restaurante_si").checked) {
        //     if (!Array.from(document.querySelectorAll(".horarios_operacion_restaurante")).some(element => Boolean(element.value.trim()))) {
        //         alert("Al menos un horario de operación del restaurante debe estar completado para continuar.");
        //         return false;
        //     };
        // };

        return validateRequiredFields(querySelectorEnergia);
    };

    const formDataToDict = (formData) => {
        const dict = {};
    
        // Iterate over FormData entries
        for (const [key, value] of formData.entries()) {
            // If the key already exists, convert it to an array or append to the existing array
            if (key in dict) {
                if (Array.isArray(dict[key])) {
                    dict[key].push(value);
                } else {
                    dict[key] = [dict[key], value];
                }
            } else {
                dict[key] = value;
            }
        }
    
        return dict;
    };

    const sendDataToBackend = () => {
        const data = new FormData();
        for (const key in formData) {
            const value = formData[key] !== null ? formData[key] : '';
    
            if (Array.isArray(value)) {
                // Handle multiple files (array of files)
                value.forEach((file) => {
                    data.append(key, file);
                });
            } else if (value instanceof FileList) {
                // Handle file inputs dynamically
                for (let i = 0; i < value.length; i++) {
                    data.append(key, value[i]);
                }
            } else if (key === 'niveles_educativos') {
                const validatedValue = value.length < 255 ? value : '';
                data.append(key, validatedValue);
            } else {
                // Handle other non-file fields
                data.append(key, value);
            }
        }

        const formDataError = formDataToDict(data)
    
        return axios.post(`${props.BACKEND_URL}/api/form/${slug}/${currentSection}`, data)
            .then(response => {
                console.log('Form data sent successfully:', response);
                return true;
            })
            .catch(error => {
                console.log('Error sending form data:', error);
                
                // Log the error to the backend
                axios.post(`${props.BACKEND_URL}/api/log-error`, {
                    cct: slug,
                    message: error.message,
                    stack: error.stack,
                    config: error.config,
                    timestamp: new Date().toISOString(),
                    data: formDataError,
                }).catch(logError => {
                    console.log('Failed to log error to backend:', logError);
                });

                // alert("Error sending form data.");
                // return false;

                // a pedidos foi retirando o return false para continuar o fluxo mesmo assim
                return true;
            });
    };

    function changeCurrentSection (nextOrBack, querySelector='', validateFunction=validateRequiredFields) {
        console.log(listCurrentSections);
        if (nextOrBack === 'next' && !validateFunction(querySelector)) {
            alert('Por favor, complete todos los campos obligatorios.');
            return;
        }
    
        console.log(currentSection);
    
        const indexCurrentSections = listCurrentSections.indexOf(currentSection);
    
        if (nextOrBack === 'next') {
            console.log(currentSection)
            console.log(indexCurrentSections)
            if (indexCurrentSections >= listCurrentSections.length - 1) {
                console.log("lenght menor")
                return;
            }
    
            // Send data to backend before moving to the next section
            sendDataToBackend().then(success => {
                if (success) {
                    setCurrentSection(listCurrentSections[indexCurrentSections + 1]);
                }
            });
        } else if (nextOrBack === 'back') {
            if (indexCurrentSections <= 0) {
                console.log("lenght menor")
                return;
            }

            // just come back to previous section
            setCurrentSection(listCurrentSections[indexCurrentSections - 1]);

            // Send data to backend before moving to the previous section
            // sendDataToBackend().then(success => {
            //     if (success) {
            //         setCurrentSection(listCurrentSections[indexCurrentSections - 1]);
            //     }
            // });
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        sendDataToBackend().then(success => {
            if (success) {
                alert('El formulario ha sido enviado exitosamente. Gracias por su tiempo y respuesta.');

                navigate(`/`);;
            } else {
                alert('Hubo un problema al envar el formulario. Por favor contacte con los responsables')
            };
        });
    };

    return (
        <div id="home">
            <header className="mb-4">
                <Container className="d-flex justify-content-between align-items-center py-3">
                    <img src="/img/BID_espanhol.png" alt="Logo BID" className="logo-mitsidi logo-header"/>
                    <img src="/img/logo_mitsidi.png" alt="Logo Mitsidi" className="logo-mitsidi logo-header" />
                </Container>
            </header>

            <Form onSubmit={handleSubmit}>
            {/* <Form> */}
                <Form.Group controlId="codigo" className="form_formulario">
                {currentSection === 'general_info' && (
                    <GeneralInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.form-control.general-info[required]')}
                    // handleNextSection={() => changeCurrentSection('next')}
                    />
                )}
                {currentSection === 'caracteristicas_arquitetonicas' && (
                    <CaracteristicasArquitetonicas
                    formData={formData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleMultipleFileChange={handleMultipleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.caracteristicas_arquitetonicas[required]')}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'energia_renovable' && (
                    <EnergiaRenovableInfo
                    formData={formData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.energia-renovable-info[required]', validateRequiredFieldsEnergiaRenovable)}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'techo' && (
                    <TechoInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.form-control.techo-info[required]', validateRequiredFieldsTecho)}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'ocupacion_operacion' && (
                    <OcupacionInfo
                    formData={formData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.ocupacion_operacion-info[required]', validateRequiredFieldsOcupacionOperacion)}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'energia' && (
                    <EnergiaInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.energia-info[required]', validateRequiredFieldsEnergia)}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'iluminacion_interna' && (
                    <IluminationInternaInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.iluminacion-info[required]')}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'iluminacion_externa' && (
                    <IluminationExternaInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.iluminacion-info[required]')}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {currentSection === 'ar_condicionados' && (
                    <ArCondicionados
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.ar-condicionado-info[required]', validateRequiredFieldsArCondicionado)}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                {/* {currentSection === 'caliamento_agua' && (
                    <CaliamentoAgua
                    formData={formData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.caliamento-agua-info[required]')}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )} */}
                {currentSection === 'outros' && (
                    <Outros
                    formData={formData}
                    handleChange={handleChange}
                    handleFileChange={handleFileChange}
                    handleNextSection={() => changeCurrentSection('next', '.outros-info[required]')}
                    // handleNextSection={() => changeCurrentSection('next')}
                    handlePreviousSection={() => changeCurrentSection('back')}
                    />
                )}
                </Form.Group>
            </Form>

            <Footer />
        </div>
    );
};

export default Formulario;

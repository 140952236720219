// src/components/PopoverComponent.js
import React from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/popover.css';

const PopoverComponent = (props) => {
    const renderTooltip = (
        <Popover id="popover-basic">
        <Popover.Body>
            {props.text}
        </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
        placement="top"
        overlay={renderTooltip}
        trigger={['hover', 'focus']}
        >
            <Button variant="default" className="btn-circle icones_duvidas">
                <i className="bi bi-info-circle-fill"></i>
            </Button>
        </OverlayTrigger>
    );
};

export default PopoverComponent;

import React, { useEffect } from 'react';
import { Button, Row, Col, Accordion } from 'react-bootstrap';
import NumericInput from './NumericInput';
import IntegerInput from './IntegerInput';
import Tooltip from 'bootstrap/js/dist/tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/card-equipamentos.css';


const ListaEquipamentosAr = ({ formData, handleChange, equipamentos, title, spanTitle }) => {
    title = title ? title : "Lista de Equipos Presentes";
    spanTitle = spanTitle ? spanTitle : "Complete los equipos presentes y sus respectivos valores";

    return (
        <>
            <h3>
                {title}
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title={spanTitle}
                >
                    <i className="bi bi-info-circle-fill custom-tooltip"></i>
                </span>
            </h3>

            <Row className="align-items-center">
                {equipamentos.map((item, index) => {
                    return (
                        <Col xs={12} sm={6}>
                            <div className={`form-check-equipamento div_${item.id} custom-checkbox-wrapper`}>
                                {item.img && <img src={item.img} alt={item.label} className="custom-checkbox-image" />}
                                <input
                                    className="form-check-input custom-checkbox-input"
                                    type="checkbox"
                                    name={item.name}
                                    id={item.id}
                                    value={item.name}
                                    checked={formData[item.name]}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label custom-checkbox-label" htmlFor={item.id}>
                                    {item.label}
                                    {item.tooltip && (
                                        <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={item.tooltip}
                                        >
                                        <i className="bi bi-info-circle-fill custom-tooltip"></i>
                                        </span>
                                    )}
                                </label>
                            </div>
                        </Col>
                    );
                })}

            </Row>
        </>
    );
};


const EquipamentosCardAr = ({formData, setFormData, handleChange, item, index}) => {
    return (
        <>
            {item.potencia_btu === true && (
                <Col xs={12} sm={12}>
                    <label htmlFor={`potencia_btu_${item.name}_${index}`}>Potencia (BTU/h):</label>
                    <NumericInput
                        id={`potencia_btu_${item.id}_${index}`}
                        name={`potencia_btu_${item.name}_${index}`}
                        className="form-control otros-equipos-info"
                        value={formData[`potencia_btu_${item.name}_${index}`]}
                        onChange={handleChange}
                    />
                </Col>
            )}
            <Col xs={12} sm={12}>
                <label htmlFor={`potencia_${item.id}`}>
                    Potencia que más se aproxima a la de tu equipo (W):
                    {item.potencia_btu_tooltip &&(
                        <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ingrese la potencia de la lámpara (en vatios), esto puede ser una estimación. Si no lo sabes, deja el campo sin respuesta.."
                        >
                            <i className="bi bi-info-circle-fill custom-tooltip"></i>
                        </span>
                    )}
                </label>
                <select
                    id={`potencia_${item.id}_${index}`}
                    name={`potencia_${item.name}_${index}`}
                    className="form-control ar-condicionado-info"
                    value={formData[`potencia_${item.name}_${index}`]}
                    onChange={handleChange}
                >
                    <option value='' hidden></option>
                    {item.listPotencias && (
                        item.listPotencias.map((element) => {
                            return <option value={element}>{element}</option>
                        })
                    )}
                </select>
                {/* <NumericInput
                    id={`potencia_${item.id}_${index}`}
                    name={`potencia_${item.name}_${index}`}
                    className="form-control iluminacao-interna-info"
                    value={formData[`potencia_${item.name}_${index}`]}
                    onChange={handleChange}
                /> */}
            </Col>
            {item.capacidad_de_enfriamiento === true && (
                <Col xs={12} sm={12}>
                    <label htmlFor={`capacidad_de_enfriamiento_${item.name}_${index}`}>Capacidad de enfriamiento (W):</label>
                    <NumericInput
                        id={`capacidad_de_enfriamiento_${item.id}_${index}`}
                        name={`capacidad_de_enfriamiento_${item.name}_${index}`}
                        className="form-control otros-equipos-info"
                        value={formData[`capacidad_de_enfriamiento_${item.name}_${index}`]}
                        onChange={handleChange}
                    />
                </Col>
            )}
            {item.reee === true && (
                <Col xs={12} sm={12}>
                    <label htmlFor={`reee_${item.name}_${index}`}>Relación de Eficiencia Energética Estacional (REEE):</label>
                    <NumericInput
                        id={`reee_${item.id}_${index}`}
                        name={`reee_${item.name}_${index}`}
                        className="form-control otros-equipos-info"
                        value={formData[`reee_${item.name}_${index}`]}
                        onChange={handleChange}
                    />
                </Col>
            )}
            <Col xs={12} sm={12}>
                <label htmlFor={`cantidad_${item.id}_${index}`}>Cantidad:</label>
                <IntegerInput
                    id={`cantidad_${item.id}_${index}`}
                    name={`cantidad_${item.name}_${index}`}
                    className="form-control iluminacao-interna-info"
                    value={formData[`cantidad_${item.name}_${index}`]}
                    onChange={handleChange}
                />
            </Col>
            {item.capacidad_litros === true && (
                <Col xs={12} sm={12}>
                    <label htmlFor={`capacidad_litros_${item.name}_${index}_${index}`}>Capacidad (litros):</label>
                    <NumericInput
                        id={`capacidad_litros_${item.id}_${index}`}
                        name={`capacidad_litros_${item.name}_${index}`}
                        className="form-control otros-equipos-info"
                        value={formData[`capacidad_litros_${item.name}_${index}`]}
                        onChange={handleChange}
                    />
                </Col>
            )}
            {item.inverter &&(
                <Col xs={12} sm={12}>
                    <label htmlFor="estado">
                        ¿Dispone de tecnología inverter?:
                    </label>
                    <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Aire acondicionado Split inverter: La unidad exterior tiene un compresor que funciona a velocidad variable para mantener la temperatura deseada. Esto los hace más eficientes energéticamente y más silenciosos que los aires acondicionados Split tradicionales. La unidad interior también tiene un ventilador que hace circular el aire sobre los serpentines de enfriamiento."
                    >
                        <i className="bi bi-info-circle-fill custom-tooltip"></i>
                    </span>
                    <select
                        id={`inverter_${item.id}_${index}`}
                        name={`inverter_${item.name}_${index}`}
                        className="form-control ar-condicionado-info"
                        value={formData[`inverter_${item.name}_${index}`]}
                        onChange={handleChange}
                    >
                        <option value='' hidden></option>
                        <option value="Sí">Sí</option>
                        <option value="No">No</option>
                    </select>
                </Col>
            )}
        </>
    );
}


const AccordionCardAr = ({formData, setFormData, handleChange, equipamentos, item, nameEquipos}) => {
    const numbersEquipaments = Array.from({ length: parseInt(formData[nameEquipos]) }, (_, index) => index);

    return (
        <Accordion defaultActiveKey="0">
            {numbersEquipaments.map((_, index) => (
                <Accordion.Item eventKey={`${index}`}>
                    <Accordion.Header>
                        {`${item.label}: Equipo ${index + 1}`}
                    </Accordion.Header>
                    <Accordion.Body>
                            <Row>
                                <EquipamentosCardAr
                                    formData={formData}
                                    setFormData={setFormData}
                                    handleChange={handleChange}
                                    item={item}
                                    index={index}
                                />
                            </Row>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

const Card = ({formData, setFormData, handleChange, equipamentos, item}) => {
    const nameEquipos = `numero_equipos_${item.name}`;
    const idEquipos = `numero_equipos_${item.name}`;
    const minEquipos = 1;
    const maxEquipos = 10;

    useEffect(() => {
        // If the current value is negative or zero, replace it with 1
        if (formData[nameEquipos] < minEquipos || !formData[nameEquipos]) {
            setFormData({
                ...formData,
                [nameEquipos]: minEquipos
            });
        } else if (formData[nameEquipos] > maxEquipos) {
            setFormData(
                {
                    ...formData,
                    [nameEquipos]: maxEquipos
            });
        }
    }, [formData]);

    return (
        <>
            <Row>
                <Col xs={12} sm={12}>
                    <label htmlFor={`potencia_btu_${item.name}`}>
                        Número de equipos diferentes:
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Ingresa el número de lámparas con diferentes potencias con este modelo que identificaste, entre ${minEquipos} y ${maxEquipos}`}
                        >
                            <i className="bi bi-info-circle-fill custom-tooltip"></i>
                        </span>
                    </label>
                    <input
                        id={idEquipos}
                        name={nameEquipos}
                        type="number"
                        value={formData[nameEquipos]}
                        className={'mb-2'}
                        placeholder="Ex: 1,000"
                        min={1} // Restrict to positive values greater than 0
                        step={1} // Increment/decrement by 1
                        onChange={handleChange}
                        onWheel={(event) => event.preventDefault()}
                        onFocus={(event) => event.target.addEventListener('wheel', (e) => e.preventDefault())}
                        onBlur={(event) => event.target.removeEventListener('wheel', (e) => e.preventDefault())}
                        onKeyDown={(event) => event.preventDefault()} 
                    />
                </Col>
            </Row>
            <Row>
                <AccordionCardAr
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    equipamentos={equipamentos}
                    item={item}
                    nameEquipos={nameEquipos}
                />
            </Row>
        </>
    )
};

const CardsAr = ({ formData, setFormData, handleChange, equipamentos }) => {
    return (
        <Accordion defaultActiveKey="0">
            {equipamentos.map((item, index) => {
                return (
                    formData[item.name] === true && (
                        <Accordion.Item eventKey={`${index}`}>
                            <Accordion.Header>{item.label}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Card formData={formData} setFormData={setFormData} handleChange={handleChange} equipamentos={equipamentos} item={item}/>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                );
            })}
        </Accordion>
    );
};

const CardsEquipamentosMultiplos = ({ formData, setFormData, handleChange, equipamentos, props={} }) => {
    return (
        <>
            <ListaEquipamentosAr
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                equipamentos={equipamentos}
                title={props.title}
                spanTitle={props.spanTitle}
            />

            <CardsAr 
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                equipamentos={equipamentos}
            />
        </>
    )
};

export default CardsEquipamentosMultiplos;

import React, { useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Tooltip from 'bootstrap/js/dist/tooltip';
import NumericInput from '../NumericInput';
import IntegerInput from '../IntegerInput';

const CaliamentoAgua = ({ formData, handleChange, handleFileChange, handlePreviousSection, handleNextSection }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
    tooltips.forEach((tooltip) => new Tooltip(tooltip));
  }, []);

  const equipamentsAirConditioning = [
    // { tipo: 'Ventilador', img: '/img/form/air_conditioning/ventilador.png' },
    {
      tipo: 'Calentador De Agua',
      id:'calentador_de_agua',
      name:'calentador_de_agua',
      img: '/img/form/caliamento_agua/calentador_agua.png',
      label: 'Ejemplo de un calentador de agua instantáneo eléctrico'
    },
    { 
      tipo: 'Regadera Eletrica',
      id:'regadera_eletrica',
      name:'regadera_eletrica',
      img: '/img/form/caliamento_agua/reguadeira_eletrica.png',
      label: 'Ejemplo de una regadera eléctrica'
    },
  ]


  return (
    <section className="form-section active" id="section-energia-renovable">
      <h2>
      Calentamiento de agua
      </h2>

      <label>¿Hay un sistema eléctrico de calentamiento de agua?</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="caliamento_agua"
              id="caliamento_agua_si"
              value="sí"
              checked={formData.caliamento_agua === 'sí'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="caliemento_agua_si">Sí</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="caliamento_agua"
              id="caliamento_agua_no"
              value="no"
              checked={formData.caliamento_agua === 'no'}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="caliemento_agua_no">No</label>
          </div>

          {formData.caliamento_agua === 'sí' && (
            <>
              {equipamentsAirConditioning.map((item, index) => (
                <div key={index}>
                  <Row className="align-items-center">
                    <Col xs={12} sm={4}>
                      <label>{item.tipo}:</label>
                      {item.tooltip && (
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title={item.tooltip}
                        >
                          <i className="bi bi-info-circle-fill custom-tooltip"></i>
                        </span>
                      )}
                    </Col>
                    <Col xs={12} sm={8} className="text-sm-right img-container">
                      {item.img && <img src={item.img} alt={`Ejemplo de ${item.tipo.toLowerCase()}`} />}
                      <p className='text-img'>{item.label}</p>
                    </Col>
                  </Row>
                  <label htmlFor={`potencia_eletrica_${item.id}`}>Potencia eléctrica (W):</label>
                  <NumericInput
                    id={`potencia_eletrica_${item.id}`}
                    name={`potencia_eletrica_${item.name}`}
                    className="form-control ar-condicionado-info"
                    value={formData[`potencia_eletrica_${item.name}`]}
                    onChange={(e) => handleChange({ target: { name: `potencia_eletrica_${item.name}`, value: e.target.value } })}
                  />
                  <label htmlFor={`cantidad_${item.id}`}>Cantidad:</label>
                  <IntegerInput
                    id={`cantidad_${item.id}`}
                    name={`cantidad_${item.name}`}
                    className="form-control ar-condicionado-info"
                    value={formData[`cantidad_${item.name}`]}
                    onChange={(e) => handleChange({ target: { name: `cantidad_${item.name}`, value: e.target.value } })}
                  />
                </div>
              ))}
            </> 
          )}

      <div style={{ marginTop: '1.5em' }}>
        <Button className="btn btn-info mt-2 text-white" variant="secondary" onClick={handlePreviousSection}>
          Anterior
        </Button>
        <Button className="btn btn-info mt-2 text-white" variant="primary" onClick={handleNextSection}>
          Próximo
        </Button>
      </div>
    </section>
  );
};

export default CaliamentoAgua;
